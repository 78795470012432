import isBrowser from '@/utils/isBrowser';

/**
 * Write to browser local storage
 * @param key string
 * @param value string
 */
export const writeToLocalStorage = (key: string, value: string): void => {
  if (isBrowser()) {
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // Do nothing on error
    }
  }
};

/**
 * Read from browser local storage
 * @param key string
 */
export const readFromLocalStorage = (key: string): string | null => {
  if (isBrowser()) {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      // Do nothing on error
    }
  }
  return null;
};

/**
 * Remove item from browser local storage
 * @param key string
 */
export const removeFromLocalStorage = (key: string): void => {
  if (isBrowser()) {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // Do nothing on error
    }
  }
};
