'use client';

import { ThemeProvider } from '@mui/material/styles';
import type { ReactNode } from 'react';

import muiTheme from '../styles/material.theme';

const MaterialThemeProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;

export default MaterialThemeProvider;
