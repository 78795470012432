'use client';

import type { UseQueryResult } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { ListProduct } from '@/queries/Products';
import { useProductsData } from '@/queries/Products';

const ProductsContext = createContext<UseQueryResult<
  ListProduct[],
  Error
> | null>(null);

export const useProductsContext = (): UseQueryResult<
  ListProduct[],
  Error
> | null => {
  return useContext(ProductsContext);
};

const ProductsProvider = ({
  children,
}: {
  readonly children: ReactNode;
}): JSX.Element => {
  const products = useProductsData({
    enabled: true,
  });

  return (
    <ProductsContext.Provider value={products}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
