/*
  Edit this file to locally force a feature flag on.

 Example:

 module.exports = {
     'bilbo-product-catalog': true
 }

*/
/* eslint-disable no-process-env */
type Flags = Record<string, boolean>;
const flags: Flags = {};

if (process.env['LOCAL_ENABLE_FEATURE_FLAG']) {
  for (const flag of process.env['LOCAL_ENABLE_FEATURE_FLAG'].split(',')) {
    // eslint-disable-next-line no-console
    console.log(`Enabling feature flag ${flag} (from environment variable)`);
    flags[flag] = true;
  }
}

if (process.env['LOCAL_DISABLE_FEATURE_FLAG']) {
  for (const flag of process.env['LOCAL_DISABLE_FEATURE_FLAG'].split(',')) {
    // eslint-disable-next-line no-console
    console.log(`Disabling feature flag ${flag} (from environment variable)`);
    flags[flag] = false;
  }
}

export default flags;
