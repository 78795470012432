'use client';

import { QueryClientProvider as ClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';

import { getQueryClient } from '@/api/queryClient';

const QueryClientProvider = ({ children }: PropsWithChildren) => {
  const queryClient = getQueryClient();

  return <ClientProvider client={queryClient}>{children}</ClientProvider>;
};

export default QueryClientProvider;
