'use client';

import {
  type AbstractIntlMessages,
  NextIntlClientProvider as IntlClientProvider,
} from 'next-intl';
import { type PropsWithChildren } from 'react';

import getLocaleFormatting from '@/utils/getLocaleFormatting';

interface NextIntlClientProviderProps extends PropsWithChildren {
  readonly locale: string;
  readonly messages: AbstractIntlMessages;
}
const NextIntlClientProvider = ({
  children,
  locale,
  messages,
}: NextIntlClientProviderProps) => {
  return (
    <IntlClientProvider
      locale={locale}
      messages={messages}
      timeZone="UTC" // default
      defaultTranslationValues={getLocaleFormatting}
    >
      {children}
    </IntlClientProvider>
  );
};

export default NextIntlClientProvider;
