import { useQuery } from '@tanstack/react-query';
import presetFeatureFlags from 'configs/presetFeatureFlags';
import { gql, request } from 'graphql-request';

import { getGraphQlEndpoint } from '@/api/backendAPI';

import type { FeaturesConfig } from './types/FeaturesConfig';

for (const [flag, value] of Object.entries(presetFeatureFlags)) {
  // eslint-disable-next-line no-console
  console.log(`Using preset feature flag: ${flag} = ${value}`);
}

const query = gql`
  query FeaturesConfig {
    content {
      flags: enabledFeatureFlags {
        featureFlagKey
        enabled
      }
    }
  }
`;

export function withQueryKey() {
  return {
    queryKey: ['featuresConfig'],
    queryFn: async () => {
      const url = getGraphQlEndpoint()!;

      const data: FeaturesConfig = await request(url, query, {});
      const asMap: Record<string, boolean> = {};
      if (data.content?.flags) {
        for (const flag of data.content.flags) {
          if (flag) {
            asMap[flag.featureFlagKey] = flag.enabled;
          }
        }
      }

      return asMap;
    },
  };
}

export function useFeatureFlagsData() {
  return useQuery(withQueryKey());
}

export function useFeatureFlag(flag: string): boolean {
  const { data } = useFeatureFlagsData();
  return data?.[flag] ?? false;
}
