import Script from 'next/script';

//* https://ouraringconfig.com/sdk/9.min.js Affiliate LP skinning tech for CP */
const AffiliatesScript = (): JSX.Element => (
  <Script
    async
    src="https://ouraringconfig.com/sdk/9.min.js"
    strategy="afterInteractive"
  />
);

export default AffiliatesScript;
