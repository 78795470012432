'use client';

import * as Sentry from '@sentry/nextjs';
import { useEnvContext } from 'next-runtime-env';

import isBrowser from '@/utils/isBrowser';

const SentryInit = () => {
  const { NEXT_PUBLIC_SENTRY_DSN, NEXT_PUBLIC_OURA_ENV, NEXT_PUBLIC_VERSION } =
    useEnvContext();

  if (isBrowser() && !Sentry.isInitialized()) {
    Sentry.init({
      dsn: NEXT_PUBLIC_SENTRY_DSN!,
      environment: NEXT_PUBLIC_OURA_ENV!,
      tracesSampleRate: 0.2,
      release: NEXT_PUBLIC_VERSION!,
      // enable when https://github.com/jouzen/ourafront/pull/6728 is released
      // tunnel: '/api/monitoring',
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error', 'assert'],
        }),
      ],
    });
  }
  return null;
};

export default SentryInit;
