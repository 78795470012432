'use client';

import type { ReactNode } from 'react';
import { createContext } from 'react';
import { useConfig } from 'statsig-react';

import { useSavePromotion } from '@/queries/SavePromotion';
import type { SavePromotion_cart_savePromotion } from '@/queries/types/SavePromotion';

interface SitewideDiscountContext {
  data: SavePromotion_cart_savePromotion | null | undefined;
  isLoading: boolean;
  isSuccess: boolean;
}

export const Context = createContext<SitewideDiscountContext | null>(null);

// Note: This provider is used to provide the sitewide discount data to the entire app.
const SitewideDiscountProvider = ({
  children,
}: {
  readonly children: ReactNode;
}): JSX.Element => {
  const {
    config: { value },
  } = useConfig('sitewide_discount');
  const { data, isLoading, isSuccess } = useSavePromotion(
    (value['sitewideDiscount'] as string | null) ?? '',
  );

  return (
    <Context.Provider value={{ data, isLoading, isSuccess }}>
      {children}
    </Context.Provider>
  );
};

export default SitewideDiscountProvider;
