'use client';

import { MotionConfig as Config } from 'framer-motion';
import type { ReactNode } from 'react';

const MotionConfig = ({
  children,
}: {
  readonly children: ReactNode;
}): JSX.Element => {
  return <Config reducedMotion="user">{children}</Config>;
};

export default MotionConfig;
