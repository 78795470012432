'use client';

import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { useFeatureFlagsData } from '@/queries/FeaturesConfig';

interface FeatureFlagsProviderProps {
  readonly children: ReactNode;
}

interface FeatureFlagsContext {
  data: Record<string, boolean>;
  isLoading: boolean;
  isSuccess: boolean;
}

const Context = createContext<FeatureFlagsContext | null>(null);

export const useFeatureFlagsContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error(
      'Did you forget to include FeatureFlagsProvider in your tree?',
    );
  }
  return ctx;
};

const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps): JSX.Element => {
  const { data, isLoading, isSuccess } = useFeatureFlagsData();

  return (
    <Context.Provider value={{ data: data!, isLoading, isSuccess }}>
      {children}
    </Context.Provider>
  );
};

export default FeatureFlagsProvider;
